@import "../rgbstyle/rgbstyle-mixins";

.el-formulario-contato {
    position: relative;
    z-index: 11;

    .formulario-container {
        padding: 40px 0;

        @include desktop {
            padding: 130px 0 90px;
            position: relative;
            width: 550px;

            &::after {
                content: '';
                position: absolute;
                //top: -30px;
                top: 0;
                bottom: 0;
                left: 625px;
                width: 910px;
                background: url(../../imagens/formulario-contato/bg.jpg) center;
                background-size: cover;
            }
        }
    }
}